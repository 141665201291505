
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import MasterService from "@/core/services/car/MasterService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RecordSelect from "../components/recordSelect.vue";
import { getMonthDate, registration, roles } from "@/store/stateless/store";
import moment from "moment";

interface IPayload {
  start_date: string;
  end_date: string;
  status: any;
  transaction_type: any;
  keyword: string;
  per_page: number | string;
  page: number;
}

export default defineComponent({
  // component name
  name: "report-account-master",
  components: {
    RecordSelect
  },
  data() {
    // constants
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            //end.setDate(1);
            //end.setHours(-1);
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ]
    };
  },
  setup() {
    const store = useStore();
    const value2 = ref<Array<string>>([]);

    let bodyPayloads = ref<IPayload>({
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      status: "",
      transaction_type: [],
      keyword: "",
      per_page: 50,
      page: 1
    });

    let exportUrl = ref<string>("");

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    let policyStatusList = computed(() => {
      return store.getters.getPolicyStatuses;
    });

    let transactionTypeList = computed(() => {
      return store.getters.getPaymentTypeList;
    });

    // let policyStatusList = ref({
    //   mode: "tags",
    //   data: [],
    //   options: policyStatus,
    //   placeholder: "Select Policy Status",
    //   searchable: true,
    //   createTag: true
    // });

    // let transactionTypeList = ref({
    //   mode: "tags",
    //   data: [],
    //   options: transactionType,
    //   placeholder: "Select Transaction Type",
    //   searchable: true,
    //   createTag: true
    // });

    const defaultBodyPayloads = computed(() => {
      return store.getters.getCommonPayLoad;
    });
    const displayResponseData = computed(() => {
      return store.getters.getAccountMasterReport;
    });
    const refundRecordCount = computed(() => {
      return store.getters.getAccountMasterRefundRecordCount;
    });
    const myPagination = computed(() => {
      return store.getters.getExportPagination;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const exportReport = () => {
      Promise.all([
        ReportService.exportingAccountMasterReport(bodyPayloads.value)
      ]).then((data) => {
        let exportResult = computed(() => {
          return store.getters.getExport;
        });
        exportUrl.value = '';
        const DownloadReportRoute = process.env.VUE_APP_API_URL + 'download-report/' + exportResult.value
        window.open(DownloadReportRoute)
      });
    };

    const searchByDate = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyPayloads.value.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
      } else {
        bodyPayloads.value.start_date = '';
        bodyPayloads.value.end_date = '';
      }
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    var payload = {
      is_account_master:true
    };

    const getPolicyStatusList = () => {
      Promise.all([MasterService.getPolicyStatuses(payload)]).then((data) => {
        policyStatusList = computed(() => {
          return store.getters.getPolicyStatuses;
        });
        // policyStatusList.value = {
        //   mode: "tags",
        //   data: [],
        //   options: policyStatus,
        //   placeholder: "Select Policy Status",
        //   searchable: true,
        //   createTag: true
        // };
      });
    };

    const onPolicyStatusChange = () => {
      // bodyPayloads.value.status = event;
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const resetPolicyStatus = () => {
      // bodyPayloads.value.status = event == null ? "" : event;
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const getTransactionTypeList = () => {
      Promise.all([MasterService.getPaymentTypes()]).then((data) => {
        transactionTypeList = computed(() => {
          return store.getters.getPaymentTypeList;
        });
        // transactionTypeList.value = {
        //   mode: "tags",
        //   data: [],
        //   options: transactionType,
        //   placeholder: "Select Transaction Type",
        //   searchable: true,
        //   createTag: true
        // };
      });
    };

    const onTransactionTypeChange = () => {
      // bodyPayloads.value.transaction_type = event;
      console.log(bodyPayloads.value)
      if(bodyPayloads.value.transaction_type.includes(2)) {
        const exist = bodyPayloads.value.transaction_type.find(x => x === 6)
        if(!exist) bodyPayloads.value.transaction_type.push(6)
      } else {
        const filtered = bodyPayloads.value.transaction_type.filter(x => x != 6)
        bodyPayloads.value.transaction_type = filtered
      }
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const resetTransactionType = () => {
      // bodyPayloads.value.transaction_type = event == null ? "" : event;
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const searchAccountMaster = () => {
      ReportService.callingAccountMasterReport(bodyPayloads.value);
    };

    const quoteAutoSuggestAPI = () => {
      if (bodyPayloads.value.keyword.length > 2) {
        return ReportService.fetchingQuoteAutoSuggest(bodyPayloads.value);
      }
    };

    let suggestedQuoteList = computed(() => {
      return store.getters.getQuoteSuggest;
    });

    const quoteAutoSuggest = async (queryString: string, cb: (arg: any) => void) => {
      const result = await quoteAutoSuggestAPI();
      const results = queryString ? suggestedQuoteList.value : suggestedQuoteList.value;
      cb(results);
    };

    const resetAndRefreshFilter = () => {
      bodyPayloads.value = {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        status: "",
        transaction_type: [],
        keyword: "",
        per_page: 50,
        page: 1
      };
      value2.value = getMonthDate.currentMonth();
      ReportService.callingAccountMasterReport(bodyPayloads.value);
      getPolicyStatusList();
      getTransactionTypeList();
      exportUrl.value = "";
    };

    const generateRefundNTitle = (val: any) => {
      switch (val) {
        case 1:
          return "";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return val + "st";
      }
    };

    onMounted(() => {
      if(!roles.reportAccountMaster.includes(roles.userId())) window.location.href = '/#/404'  
      
      
      setCurrentPageBreadcrumbs("Account Master", ["Reports"]);
      ReportService.callingAccountMasterReport(defaultBodyPayloads.value);
      // getPolicyStatusList();
      getTransactionTypeList();
      value2.value = getMonthDate.currentMonth();
    });

    const currentPage = ref(1);

    const currency = (e) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: registration.currency
      });

      return formatter.format(e).replace(registration.currency, "");
    };
    const loading = computed(() => {
      return store.getters.getARLoading;
    });
   

    return {
      registration,
      loading,
      roles,
      value2,
      currentPage,
      bodyPayloads,
      defaultBodyPayloads,
      displayResponseData,
      myPagination,
      onPerPageChange,
      getPaginationUrl,
      resetAndRefreshFilter,
      exportUrl,
      exportReport,
      disableSaveButton,
      searchByDate,
      policyStatusList,
      onPolicyStatusChange,
      resetPolicyStatus,
      refundRecordCount,
      transactionTypeList,
      onTransactionTypeChange,
      resetTransactionType,
      searchAccountMaster,
      quoteAutoSuggest,
      suggestedQuoteList,
      generateRefundNTitle,
      currency
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.myPagination.current_page * this.myPagination.per_page -
        this.myPagination.per_page +
        index +
        1
      );
    }
  }
});
